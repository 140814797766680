import { Component, AfterViewInit, Inject } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements AfterViewInit {
  showSettings: boolean = false;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private msalAuthService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}
  async ngOnInit() {
    this.showSettings = await this.authService.showSettings();
  }

  ngAfterViewInit(): void {}
  isMenuOpen = false;

  openMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  navigateToSettings() {
    if (this.authService.isSettingsVisible()) {
      this.router.navigate(['/settings']); // Navigate to settings route if user is an Admin
    } else {
      alert('You are not authorized to view this page.');
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalAuthService.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalAuthService.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
  }
}
