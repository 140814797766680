<div class="translator-card">
  <h2>How to start the Live Translation Desktop Application:</h2>
  <div class="instruction-container">
    <ol>
      <li>
        Navigate to the translation-desktop-app.exe file in Live translation
        desktop app package.
      </li>
      <li>
        Double click on the translation-desktop-app.exe file to start the
        application.
      </li>
      <li>
        Wait for few seconds for the application to gracefully start and then
        check for Live Translate application in the systems tray. The Live
        Translate application is represented by 'T' icon.
      </li>
      <li>
        Right click on the 'T' icon to view the menu options available in the
        Live Translation dekstop app.
      </li>
      <li>
        Open the configuration window from the menu options to see the features
        supported by the app and the configuration options available.
      </li>
      <li>Select source and target language for the translation.</li>
      <li>Press Ctrl+I for doing Image to Image Translation.</li>
      <li>Press Ctrl+T for doing Image to Text Translation.</li>
      <li>
        To quit the application, do a right click on the 'T' icon in the systme
        tray and click on the quit option.
      </li>
      <li>
        For any issues and assistance required in using the Live Translation on your
        computer you may contact our support team. Support team email id: {{ 'DTSE_Translation_Suite@mg.telekom.de' }}
      </li>
    </ol>
  </div>
  <!-- Download Button -->
 <!--  <a class="download-button" href="link_to_your_extension_file.zip" download
    >Download Extension</a
  > -->
</div>
