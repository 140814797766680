<form
  [formGroup]="signupForm"
  (ngSubmit)="onSubmit()"
  aria-labelledby="group-report-header">
  <div class="grid-container">
    <h1 id="group-report-header" class="grid-header">Reports</h1>

    <!-- Start Date -->
    <div class="grid-start-date">
      <scale-date-picker
        label="Start Date (in UTC)"
        id="startDate"
        name="startDate"
        required
        aria-required="true"
        aria-describedby="startDate-helper"
        formControlName="startDate"
        ngDefaultControl
        class="form-control"
        (scale-change)="onDateChange('startDate', $event)">
      </scale-date-picker>
    </div>

    <!-- End Date -->
    <div class="grid-end-date">
      <scale-date-picker
        label="End Date (in UTC)"
        id="endDate"
        name="endDate"
        value="2024-10-24"
        required
        aria-required="true"
        aria-describedby="endDate-helper"
        formControlName="endDate"
        ngDefaultControl
        class="form-control"
        (scale-change)="onDateChange('endDate', $event)">
      </scale-date-picker>
    </div>

    <!-- Submit Button -->
    <div class="grid-submit">
      <scale-button type="submit" aria-label="View report for selected dates">
        <app-loading-spinner aria-hidden="true"></app-loading-spinner>View
        Report
      </scale-button>
    </div>
  </div>
</form>
<div *ngIf="successfulFetch">
  <div class="reports-grid-container">
    <div class="reports-grid-item">
      <p><b>Group Based Usage Report</b></p>
      <scale-table show-sort>
        <table>
          <caption></caption>
          <thead>
            <tr>
              <!-- aria-sort can be descending or ascending -->
              <th
                tabindex="0"
                aria-sort="descending"
                (click)="sortTable('group')"
                id="group">
                Group Name
              </th>
              <th
                tabindex="0"
                onkeyup="handleKeyUp(event, 'consumption')"
                (click)="sortTable('consumption')"
                id="consumption">
                Group Usage (in %)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let group of groupUsage | keyvalue">
              <td>{{ group.key }}</td>
              <td>{{ group.value }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Total translation requests processed</td>
              <td>{{ totalHits }}</td>
            </tr>
          </tfoot>
        </table>
      </scale-table>
    </div>
    <div class="reports-grid-item">
      <p><b>Cost Analysis Report</b></p>
      <scale-table show-sort>
        <table>
          <caption></caption>
          <thead>
            <tr>
              <!-- aria-sort can be descending or ascending -->
              <th
                tabindex="0"
                aria-sort="descending"
                (click)="sortTable('group')"
                id="group">
                Cost Parameter
              </th>
              <th
                tabindex="0"
                onkeyup="handleKeyUp(event, 'consumption')"
                (click)="sortTable('consumption')"
                id="consumption">
                Cost (in EUR)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Infrastructure Cost</td>
              <td>{{ infrastructureCost }}</td>
            </tr>
            <tr>
              <td>Cognitive Services Cost</td>
              <td>{{ cognitiveSvcCost }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Total Infrastructure Cost</td>
              <td>{{ totalInfrastructureCost }}</td>
            </tr>
          </tfoot>
        </table>
      </scale-table>
    </div>
  </div>
</div>
